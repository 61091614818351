//react imports
import React, { Component } from "react";

//redux imports
import { connect } from "react-redux";

//css
import "./NewOrder.css";

//actions
import { throwAlert } from "../../../actions/generalActions";
import axios from "axios";

class App extends Component {
  state = {};

  render() {
    const products = `QuickRoll
  QuickCast
  Castus Mini
  C4
  C8
  Video On Demand
  Cloud Closed Captions
  CASTUS STREAM
  Mobile VOD
  Roku with CASTUS`
      .split("\n")
      .map((item) => {
        return (
          <option value={item} key={item}>
            {item}
          </option>
        );
      });

    return (
      <div className="container">
        <div class="row row-grey">
          <div class="col-sm-12">
            <h2>Dealer Information</h2>
          </div>
          <div class="col-sm-6 col-padding">
            <input
              type="text"
              class="form-control"
              placeholder="First name"
              onChange={(e) => {
                this.setState({ "DEALER~First Name": e.target.value });
              }}
            />
          </div>
          <div class="col-sm-6 col-padding">
            <input
              type="text"
              class="form-control"
              placeholder="Last name"
              onChange={(e) => {
                this.setState({ "DEALER~Last Name": e.target.value });
              }}
            />
          </div>
          <div class="col-sm-12 col-padding">
            <input
              type="text"
              class="form-control"
              id="human"
              name="human"
              placeholder="Email"
              onChange={(e) => {
                this.setState({ "DEALER~Email": e.target.value });
              }}
            />
          </div>
          <div class="col-sm-12 col-padding">
            <input
              type="text"
              class="form-control"
              placeholder="Company Name"
              onChange={(e) => {
                this.setState({ "DEALER~Company Name": e.target.value });
              }}
            />
          </div>
        </div>
        <div class="row row-grey">
          <div class="col-sm-12">
            <h2>Project Information</h2>
          </div>
          <div class="col-sm-12 col-padding">
            <input
              type="text"
              class="form-control"
              id="human"
              name="human"
              placeholder="Company Name"
              onChange={(e) => {
                this.setState({ "Company Name": e.target.value });
              }}
            />
          </div>
          <div class="col-sm-12 col-padding">
            <input
              type="text"
              class="form-control"
              id="human"
              name="human"
              placeholder="Project Name"
              onChange={(e) => {
                this.setState({ "Project Name": e.target.value });
              }}
            />
          </div>
          <div class="col-sm-12 col-padding">
            <input
              type="text"
              class="form-control"
              id="human"
              name="human"
              placeholder="Contact Name"
              onChange={(e) => {
                this.setState({ "Contact Name": e.target.value });
              }}
            />
          </div>
          <div class="col-sm-8 col-padding">
            <input
              type="text"
              class="form-control"
              id="human"
              name="human"
              placeholder="Address"
              onChange={(e) => {
                this.setState({ Address: e.target.value });
              }}
            />
          </div>
          <div class="col-sm-2 col-padding">
            <form action="/action_page.php">
              <select
                class="form-control"
                name="state"
                id="state"
                onChange={(e) => {
                  this.setState({ State: e.target.value });
                }}
              >
                <option value="" selected="selected">
                  Select a State
                </option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </form>
          </div>
          <div class="col-sm-2 col-padding">
            <input
              type="text"
              class="form-control"
              id="human"
              name="human"
              placeholder="Zip Code"
              onChange={(e) => {
                this.setState({ "Zip Code": e.target.value });
              }}
            />
          </div>
          <div class="col-sm-6 col-padding">
            <input
              type="text"
              class="form-control"
              id="human"
              name="human"
              placeholder="Email"
              onChange={(e) => {
                this.setState({ Email: e.target.value });
              }}
            />
          </div>
          <div class="col-sm-6 col-padding">
            <input
              type="text"
              class="form-control"
              id="human"
              name="human"
              placeholder="Phone Number"
              onChange={(e) => {
                this.setState({ "Phone Number": e.target.value });
              }}
            />
          </div>
          <div class="col-sm-12 col-padding">
            <input
              type="text"
              class="form-control"
              id="human"
              name="human"
              placeholder="Application (PEG Station, Cruise Ship, Cable Company, ECT)"
              onChange={(e) => {
                this.setState({
                  "Application (PEG Station, Cruise Ship, Cable Company, ECT)":
                    e.target.value,
                });
              }}
            />
          </div>
          <div class="col-sm-12 col-padding">
            <input
              type="text"
              class="form-control"
              id="human"
              name="human"
              placeholder="Project Timing"
              onChange={(e) => {
                this.setState({ "Project Timing": e.target.value });
              }}
            />
          </div>
          <div class="col-sm-2 col-padding">
            <select
              class="form-control"
              name="product"
              size="3"
              multiple
              onChange={(e) => {
                this.setState({ product: e.target.value });
              }}
            >
              {products}
            </select>
          </div>
          <div class="col-sm-10 col-padding">
            <input
              type="text"
              class="form-control"
              id="human"
              name="human"
              placeholder="Additonal Product Information (Number of Channels, ECT)"
              onChange={(e) => {
                this.setState({
                  "Additonal Product Information (Number of Channels, ECT)":
                    e.target.value,
                });
              }}
            />
            <input
              type="text"
              class="form-control"
              id="human"
              name="human"
              placeholder="Current Method for playout, scheduling & streaming"
              style={{ marginTop: ".5rem" }}
              onChange={(e) => {
                this.setState({
                  "Current Method for playout, scheduling & streaming":
                    e.target.value,
                });
              }}
            />
          </div>
        </div>
        <button
          className="btn btn-block btn-secondary btn-lg btn-grey"
          onClick={() => {
            if (Object.keys(this.state).length >= 15) {
              axios
                .post(this.props.url + "/order/add", {
                  item: this.state,
                  email: this.props.email,
                  auth: this.props.auth,
                })
                .then((res) => {
                  if (res.data.success) {
                    this.props.throwAlert({
                      title: "Success!",
                      body:
                        "Thank you for submitting your order details.  A notification has been sent!",
                      theme: "success",
                      redirect: "HOME",
                    });
                  }
                });
            } else {
              this.props.throwAlert({
                title: "Invalid Input",
                body: "Please make sure you fill out all of the fields!",
                theme: "err",
              });
            }
          }}
        >
          Submit
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  url: state.generalState.url,
  auth: state.generalState.user.token,
  email: state.generalState.user.email,
});

export default connect(mapStateToProps, { throwAlert })(App);
