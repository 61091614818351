//react imports
import React, { Component } from "react";

//redux imports
import { connect } from "react-redux";

//css
import "./Home.css";

//actions
import { setPage } from "../../../actions/generalActions";

import axios from "axios";

import Item from "../../helpers/item";

class App extends Component {
  state = {
    orders: [],
    que: [],
    collapse: false,
    expand: false,
    sort: false,
    search: "",
    page: 1
  };
  componentDidMount() {
    this.load_orders(1);
  }
  load_orders = page => {
    axios
      .post(this.props.url + "/order/get", {
        auth: this.props.auth,
        email: this.props.email,
        page: page
      })
      .then(res => {
        if (res.data.success) {
          this.setState({
            que: this.state.que.concat(res.data.orders).sort((a, b) => {
              return new Date(b.time) - new Date(a.time);
            }),
            orders: this.state.orders.concat(res.data.orders),
            page: page
          });
        }
      });
  };
  load_all_orders = () => {
    axios
      .post(this.props.url + "/order/get-all", {
        auth: this.props.auth,
        email: this.props.email
      })
      .then(res => {
        if (res.data.success) {
          this.setState({
            que: res.data.orders.sort((a, b) => {
              return new Date(b.time) - new Date(a.time);
            }),
            orders: res.data.orders
          });
        }
      });
  };
  search = val => {
    if (val !== "") {
      let que = this.state.orders;
      let new_que = [];
      let len = val.length;
      let counter_1 = 0;
      let counter_2 = 0;
      let isMatch = false;

      que.forEach(obj => {
        counter_1++;
        counter_2 = 0;
        isMatch = false;

        let date = `${new Date(obj.time).getDate()}`;
        let month = `${new Date(obj.time).getMonth() + 1}`;
        let year = `${new Date(obj.time).getFullYear()}`;

        Object.keys(obj.request).forEach(item => {
          try {
            if (
              (obj.request[item].substring(0, len).toLowerCase() ===
                val.toLowerCase() ||
                date.substring(0, len) === val ||
                month.substring(0, len) === val ||
                year.substring(0, len) === val) &&
              !isMatch
            ) {
              new_que.push(obj);
              isMatch = true;
            }
          } catch {
          } finally {
            counter_2++;
            if (
              counter_2 === Object.keys(obj.request).length &&
              counter_1 === que.length
            ) {
              this.setState({ que: new_que });
              this.collapse();
            }
          }
        });
      });
    } else {
      this.setState({ que: this.state.orders });
    }
  };
  collapse = () => {
    this.setState({ collapse: true });
    setTimeout(() => {
      this.setState({ collapse: false });
    }, 100);
  };
  render() {
    var Processing = this.state.que.map(item => {
      return (
        <Item
          item={item}
          collapse={this.state.collapse}
          expand={this.state.expand}
        />
      );
    });

    return (
      <div className="container">
        <ul className="list-group">
          <li className="list-group-item">
            <div className="row">
              <div className="col-1">
                <h1 className="display-4 sm">Orders</h1>
              </div>
              <div className="col">
                <div className="input-group center">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fas fa-search"></i>
                    </span>
                  </div>
                  <input
                    onChange={e => {
                      this.search(e.target.value);
                    }}
                    type="text"
                    className="form-control"
                    placeholder="Search Orders"
                  />
                </div>
              </div>
              <div
                title="Create new order"
                className="bg-success text-white padding-10px"
                onClick={() => {
                  this.props.setPage("NEW_ORDER");
                }}
              >
                <i className="fas fa-plus-circle"></i>
              </div>
              <div
                title="Expand all"
                className="bg-info text-white padding-10px"
                onClick={() => {
                  this.setState({ expand: true });
                  setTimeout(() => {
                    this.setState({ expand: false });
                  }, 100);
                }}
              >
                <i className="fas fa-expand-arrows-alt"></i>
              </div>
              <div
                title="Collapse all"
                className="bg-danger text-white padding-10px"
                onClick={() => {
                  this.collapse();
                }}
              >
                <i className="fas fa-compress-arrows-alt"></i>
              </div>
              <div
                title={`Sort by date (${
                  this.state.sort ? "Newest first" : "Oldest first"
                })`}
                className="bg-warning text-white padding-10px"
                onClick={() => {
                  this.setState({
                    sort: !this.state.sort,
                    que: this.state.que.sort((a, b) => {
                      if (this.state.sort) {
                        return new Date(b.time) - new Date(a.time);
                      } else {
                        return new Date(a.time) - new Date(b.time);
                      }
                    })
                  });
                }}
              >
                <i
                  className={`fas fa-caret-down ${
                    this.state.sort ? "rotate-main-pre" : "rotate-main-post"
                  }`}
                ></i>
              </div>
            </div>
          </li>

          {Processing}
          <li className="list-group-item text-white text-center no-gap">
            <button
              className="bg-success full qtr"
              onClick={() => {
                this.load_orders(this.state.page + 1);
              }}
            >
              Load more
            </button>
            <button
              className="bg-info full qtr3"
              onClick={() => {
                this.load_all_orders();
              }}
            >
              Load all
            </button>
          </li>
        </ul>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.generalState.user.token,
  email: state.generalState.user.email,
  url: state.generalState.url
});

export default connect(mapStateToProps, { setPage })(App);
