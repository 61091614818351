//react imports
import React, { Component } from "react";

//redux imports
import { connect } from "react-redux";

//Components
import Login from "./Components/screens/Login/Login";
import Home from "./Components/screens/Home/Home";
import Nav from "./Components/layout/Nav";
import NewOrder from "./Components/screens/NewOrder/NewOrder";
import Alert from "./Components/layout/Alert";

class Router extends Component {
  render() {
    switch (this.props.page) {
      case "LOGIN":
        return (
          <div>
            <Alert />
            <Login />
          </div>
        );
      case "NEW_ORDER":
        return (
          <div>
            <Alert />
            <Nav />
            <NewOrder />
          </div>
        );
      case "HOME":
        return (
          <div>
            <Alert />
            <Nav />
            <Home />
          </div>
        );
    }
  }
}
const mapStateToProps = state => ({
  page: state.generalState.page
});

export default connect(mapStateToProps, {})(Router);
