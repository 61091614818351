//react imports
import React, { Component } from "react";

//redux imports
import { connect } from "react-redux";

//css
import "./item.css";

//actions
import { setPage, toggleAlert } from "../../actions/generalActions";

import axios from "axios";

class App extends Component {
  state = {
    css: true,
    css2: true
  };
  componentDidUpdate() {
    if (this.props.collapse && !this.state.css) {
      this.setState({ css: true });
      setTimeout(() => {
        this.setState({ css2: true });
      }, 450);
    }
    if (this.props.expand && this.state.css) {
      this.setState({ css: false, css2: false });
    }
  }
  parseTime = time => {
    return `${time.getHours() > 12 ? time.getHours() - 12 : time.getHours()}:${
      time.getMinutes() > 10 ? time.getMinutes() : "0" + time.getMinutes()
    }${time.getHours() > 12 ? " PM" : " AM"}`;
  };
  parseDateTime = date => {
    return ` ${date.getMonth() +
      1}/${date.getDate()}/${date.getFullYear()} at ${this.parseTime(date)}`;
  };

  render() {
    var fields = Object.keys(this.props.item.request).map(item => {
      if (item.split("~")[0] !== "DEALER") {
        return (
          <li className="list-group-item">
            <div className="row">
              <div className="col">
                {item}
                {":  "}
              </div>
              <div className="col">{this.props.item.request[item]}</div>
            </div>
          </li>
        );
      } else {
        return null;
      }
    });
    return (
      <li
        style={{
          height: this.state.css
            ? "3rem"
            : `${Object.keys(this.props.item.request).length * 2.5 + 1}rem`
        }}
        className={`list-group-item ${this.state.css ? "collapsed" : "expand"}`}
        onClick={() => {
          this.setState({
            css: !this.state.css,
            css2: this.state.css2 ? !this.state.css2 : this.state.css2
          });
          if (!this.state.css2) {
            setTimeout(() => {
              this.setState({ css2: true });
            }, 450);
          }
        }}
      >
        <strong>{"Company Name: "}</strong>
        {this.props.item.request["Company Name"]}
        {"\t"}
        <strong>{"Contact Name: "}</strong>
        {this.props.item.request["Contact Name"]}
        {"\t"}
        <strong>{"Product: "}</strong>
        {this.props.item.request["product"]}
        {"\t"}
        <strong>{"Date: "}</strong>
        {this.parseDateTime(new Date(this.props.item.time))}
        <i
          className={`fas fa-caret-down float-right ${
            this.state.css2 ? "rotate-pre" : "rotate-post"
          }`}
        ></i>

        <ul className={`list-group pad ${this.state.css2 ? "d-none" : ""}`}>
          {fields}
        </ul>
      </li>
    );
  }
}
const mapStateToProps = state => ({
  url: state.generalState.url
});

export default connect(mapStateToProps, { setPage, toggleAlert })(App);
