import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const initialState = {};

const middleware = [thunk];

var store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middleware)
  //   compose(
  //     applyMiddleware(...middleware),
  //     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  //   )
);

export default store;
