import axios from "axios";

export const login = (user, creds) => dispatch => {
  localStorage.setItem("email", creds.email);
  localStorage.setItem("password", creds.password);
  dispatch({
    type: "LOGIN",
    payload: user
  });
};
export const auth = url => dispatch => {
  let email = localStorage.getItem("email");
  let password = localStorage.getItem("password");
  if (email === null || password === null) {
    dispatch({
      type: "FAILED"
    });
  } else {
    axios
      .post(url + "/auth/login", { email: email, password: password })
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: "LOGIN",
            payload: res.data.user
          });
        } else {
          dispatch({
            type: "FAILED"
          });
        }
      });
  }
};

export const setPage = page => dispatch => {
  dispatch({
    type: "SET_PAGE",
    payload: page
  });
};

export const toggleAlert = bool => dispatch => {
  dispatch({
    type: "ALERT",
    payload: bool
  });
};
export const throwAlert = obj => dispatch => {
  dispatch({
    type: "THROW_ALERT",
    payload: obj
  });
};

export const logout = () => dispatch => {
  localStorage.removeItem("email");
  localStorage.removeItem("password");
  dispatch({
    type: "LOGOUT"
  });
};
