//react imports
import React, { Component } from "react";

//redux imports
import { connect } from "react-redux";

//css
import "./Login.css";

//actions
import { login, auth, throwAlert } from "../../../actions/generalActions";

import axios from "axios";

class App extends Component {
  state = {
    email: null,
    password: null,
    shouldLoad: false,
    message: "",
  };
  componentDidMount() {
    this.props.auth(this.props.url);
    setTimeout(() => {
      this.setState({ shouldLoad: true });
    }, 3000);
  }
  submit = () => {
    if (this.state.email && this.state.password) {
      axios
        .post(this.props.url + "/auth/login", {
          email: this.state.email,
          password: this.state.password,
        })
        .then((res) => {
          if (res.data.success) {
            console.log(res.data.user.token);
            this.props.login(res.data.user, {
              email: this.state.email,
              password: this.state.password,
            });
          } else {
            this.setState({ message: res.data.message });
          }
        });
    }
  };
  render() {
    if (this.props.shouldLoad) {
      return (
        <div className="body">
          <div className="title-wrapper">
            <div className="h1-wrapper">
              <h1 className="title">CASTUS Dealer Portal</h1>
            </div>
          </div>

          <div className="App">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col">
                <div className="login-body">
                  <div className="form-group text-center">
                    <p className="login-title">Login</p>
                  </div>
                  <div className="form-group">
                    <input
                      placeholder="Email"
                      className="form-control"
                      type="email"
                      onChange={(e) => {
                        this.setState({ email: e.target.value });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      placeholder="Password"
                      className="form-control"
                      type="password"
                      onChange={(e) => {
                        this.setState({ password: e.target.value });
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <button
                      className="btn btn-block btn-primary"
                      onClick={() => {
                        this.submit();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-block btn-info"
                      onClick={() => {
                        window.open("https://dealer-registration.castus.tv");
                      }}
                    >
                      Don't have an account? Click here to register!
                    </button>
                  </div>

                  <div className="form-group">
                    <div
                      className="alert alert-danger"
                      style={{
                        display: this.state.message.length > 0 ? "" : "none",
                      }}
                      onClick={() => {
                        this.setState({ message: "" });
                      }}
                    >
                      {this.state.message}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>

          <div
            className="text-center footer"
            style={{ width: "100%", fontSize: ".75rem" }}
          >
            ∙ CASTUS Corp ∙ 165 NE Juniper Street Suite 200 Issaquah, WA 98027 ∙
            Call us at 800.215.3880 ∙ castus@castus.tv ∙
          </div>
        </div>
      );
    } else {
      return <div className="loading">LOADING</div>;
    }
  }
}
const mapStateToProps = (state) => ({
  url: state.generalState.url,
  shouldLoad: state.generalState.shouldLoad,
});

export default connect(mapStateToProps, { login, auth, throwAlert })(App);
