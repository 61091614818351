//react imports
import React, { Component } from "react";

//redux imports
import { connect } from "react-redux";

//css
import "./Nav.css";

//actions
import { setPage, throwAlert, logout } from "../../actions/generalActions";

class App extends Component {
  render() {
    return (
      <div className="header Gradient">
        <p className="p" onClick={() => this.props.setPage("HOME")}>
          {/* <i className="fas fa-home"></i> */}
          Home
        </p>
        <p className="p" onClick={() => this.props.logout()}>
          {/* <i className="fas fa-sign-out-alt"></i> */}
          Logout
        </p>
        <div className="title">CASTUS Dealer Project Registration</div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { setPage, throwAlert, logout })(App);
