const initialState = {
  page: "LOGIN",
  // url: "https://ah7idic4he.execute-api.us-west-2.amazonaws.com/default",
  url:
    "https://rjjuumwlml.execute-api.us-east-1.amazonaws.com/default/support-portal",
  user: {},
  alert: false,
  alertBody: "",
  alertTitle: "",
  alertTheme: "err",
  redirect: "",
  shouldLoad: false
};
export default function(state = initialState, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload,
        page: "HOME"
      };
    case "SET_PAGE":
      return {
        ...state,
        page: action.payload
      };
    case "FAILED":
      return { ...state, shouldLoad: true };
    case "ALERT":
      return {
        ...state,
        alert: action.payload,
        alertBody: "",
        alertTheme: "err",
        alertTitle: "",
        redirect: ""
      };
    case "THROW_ALERT":
      return {
        ...state,
        alertBody: action.payload.body,
        alertTheme: action.payload.theme,
        alertTitle: action.payload.title,
        redirect: action.payload.redirect,
        alert: true
      };
    case "LOGOUT":
      return {
        ...state,
        page: "LOGIN"
      };
    default:
      return state;
  }
}
