//react imports
import React, { Component } from "react";

//redux imports
import { connect } from "react-redux";

//css
import "./Alert.css";

//actions
import { setPage, toggleAlert } from "../../actions/generalActions";

class App extends Component {
  state = {
    shouldUpdate: true,
    css: "delete",
    isOpen: false
  };
  componentDidUpdate() {
    if (
      this.props.alert &&
      this.state.isOpen !== this.props.alert &&
      this.state.shouldUpdate
    ) {
      this.setState({ shouldUpdate: false });
      this.fadeIn();
    } else if (
      !this.props.alert &&
      this.state.isOpen !== this.props.alert &&
      this.state.shouldUpdate
    ) {
      this.setState({ shouldUpdate: false });
      this.fadeOut();
    }
  }
  fadeOut = () => {
    this.setState({
      css: "hide"
    });
    setTimeout(() => {
      this.setState({
        css: "delete",
        shouldUpdate: true,
        isOpen: false
      });
    }, 250);
  };
  fadeIn = () => {
    this.setState({
      css: "hide"
    });
    setTimeout(() => {
      this.setState({
        css: "show",
        shouldUpdate: true,
        isOpen: true
      });
    }, 10);
  };
  render() {
    return (
      <div className={`alert-bg ${this.state.css}`}>
        <div
          className="alert-body"
          style={{
            backgroundColor:
              this.props.theme === "err"
                ? "#e77681"
                : this.props.theme === "success"
                ? "#48d368"
                : "white",
            borderColor:
              this.props.theme === "err"
                ? "#a71d2a"
                : this.props.theme === "success"
                ? "#19692c"
                : "white",
            color:
              this.props.theme === "err"
                ? "#a71d2a"
                : this.props.theme === "success"
                ? "#19692c"
                : "grey"
          }}
        >
          <div className="alert-header">
            <h1>{this.props.title}</h1>
          </div>
          <div className="alert-p">
            <p>{this.props.body}</p>
          </div>
          <div className="alert-btns">
            <button
              style={{
                borderColor:
                  this.props.theme === "err"
                    ? "#a71d2a"
                    : this.props.theme === "success"
                    ? "#19692c"
                    : "white",
                color:
                  this.props.theme === "err"
                    ? "#a71d2a"
                    : this.props.theme === "success"
                    ? "#19692c"
                    : "grey"
              }}
              onClick={() => {
                if (
                  this.props.redirect !== "" &&
                  typeof this.props.redirect !== "undefined"
                ) {
                  this.props.setPage(this.props.redirect);
                }
                this.props.toggleAlert(false);
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  alert: state.generalState.alert,
  body: state.generalState.alertBody,
  title: state.generalState.alertTitle,
  theme: state.generalState.alertTheme,
  redirect: state.generalState.redirect
});

export default connect(mapStateToProps, { setPage, toggleAlert })(App);
